@import "src/styles/modules/colours";
@import "src/styles/modules/typography";
@import "src/styles/modules/overrides";


html,
body {
  background-color: $brandBlue;
  color: $white;
  font: 14px $brandPrimaryFont;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

div {
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  color: $white;
  text-decoration: none;
  transition: 250ms all ease-in-out;

  &:hover,
  &:active {
    opacity: 0.5;
  }
}
