// Everett Light
@font-face {
  font-family: "Everett Light";
  src: url("../../assets/fonts/everett-light/TWKEverett-Light-web.woff") format("woff"),
  url("../../assets/fonts/everett-light/TWKEverett-Light-web.woff2") format("woff2"),
  url("../../assets/fonts/everett-light/TWKEverett-Light-web.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
// Playfair Display
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,400..900&display=swap");


$brandPrimaryFont: "Everett Light", Helvetica, Arial, sans_serif;
$brandSecondaryFont: "Playfair Display";
