@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,400..900&display=swap);
@font-face{font-family:"Everett Light";src:url(/static/media/TWKEverett-Light-web.1c41569e.woff) format("woff"),url(/static/media/TWKEverett-Light-web.d499117d.woff2) format("woff2"),url(/static/media/TWKEverett-Light-web.45163b3a.ttf) format("truetype");font-weight:normal;font-style:normal;font-display:swap}._lowercase{text-transform:lowercase !important}._revert-case{text-transform:none !important}html,body{background-color:#0c1b24;color:#fff;font:14px "Everett Light",Helvetica,Arial,sans_serif;margin:0;padding:0;box-sizing:border-box}div{box-sizing:border-box}ul{margin:0;padding:0}a{color:#fff;text-decoration:none;transition:250ms all ease-in-out}a:hover,a:active{opacity:0.5}

/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}*/

/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

